<template>
    <div>
        <template v-if="time">
            <span
                :key="key"
                v-for="(time, key) of convertedTime">
                <template v-if="time">
                    {{ time }}{{ $t(`webmaster.offer.aside.shortUnitsTime.${ key }`) }}
                </template>
            </span>
        </template>
        <template v-else>
            0
        </template>
    </div>
</template>

<script>
  export default {
    name: "ConvertedTimeOutput",

    props: {
      time: {
        type: Number,
        default: 0,
        required: true
      }
    },

    computed: {
      convertedTime () {
        const { time } = this;
        const DAY = 1440;
        const HOUR = 60;
        return {
          days: Math.floor(time / DAY),
          hours: Math.floor((time - Math.floor(time / DAY) * DAY) / HOUR),
          minutes: Math.round(time % HOUR)
        };
      }
    }
  };
</script>

<style scoped>

</style>
